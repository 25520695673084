import { TokenType } from "./tokenizer";

export interface Glyph {
  glyph: string;
  key: string | null;
  type: TokenType;
  hint?: string;
  help?: string;
  paren?: string;
}

export default [
  { glyph: "+", key: null, type: TokenType.FUNCTION,
    hint: "Conjugate\nAdd",
    help: "https://mlochbaum.github.io/BQN/help/conjugate_add.html" },
  { glyph: "-", key: null, type: TokenType.FUNCTION,
    hint: "Negate\nSubtract",
    help: "https://mlochbaum.github.io/BQN/help/negate_subtract.html" },
  { glyph: "×", key: "=", type: TokenType.FUNCTION,
    hint: "Sign\nMultiply",
    help: "https://mlochbaum.github.io/BQN/help/sign_multiply.html" },
  { glyph: "÷", key: "-", type: TokenType.FUNCTION,
    hint: "Reciprocal\nDivide",
    help: "https://mlochbaum.github.io/BQN/help/reciprocal_divide.html" },
  { glyph: "⋆", key: "+", type: TokenType.FUNCTION,
    hint: "Exponential\nPower",
    help: "https://mlochbaum.github.io/BQN/help/exponential_power.html" },
  { glyph: "√", key: "_", type: TokenType.FUNCTION,
    hint: "Square Root\nRoot",
    help: "https://mlochbaum.github.io/BQN/help/squareroot_root.html" },
  { glyph: "⌊", key: "b", type: TokenType.FUNCTION,
    hint: "Floor\nMinimum",
    help: "https://mlochbaum.github.io/BQN/help/floor_minimum.html" },
  { glyph: "⌈", key: "B", type: TokenType.FUNCTION,
    hint: "Ceiling\nMaximum",
    help: "https://mlochbaum.github.io/BQN/help/ceiling_maximum.html" },
  { glyph: "∧", key: "t", type: TokenType.FUNCTION,
    hint: "Sort Up\nAnd",
    help: "https://mlochbaum.github.io/BQN/help/sortup_and.html" },
  { glyph: "∨", key: "v", type: TokenType.FUNCTION,
    hint: "Sort Down\nOr",
    help: "https://mlochbaum.github.io/BQN/help/sortdown_or.html" },
  { glyph: "¬", key: "~", type: TokenType.FUNCTION,
    hint: "Not\nSpan",
    help: "https://mlochbaum.github.io/BQN/help/not_span.html" },
  { glyph: "|", key: null, type: TokenType.FUNCTION,
    hint: "Absolute Value\nModulus",
    help: "https://mlochbaum.github.io/BQN/help/absolutevalue_modulus.html" },
  { glyph: "≤", key: "<", type: TokenType.FUNCTION,
    hint: "Less Than or Equal to",
    help: "https://mlochbaum.github.io/BQN/help/lessthanorequalto.html" },
  { glyph: "<", key: null, type: TokenType.FUNCTION,
    hint: "Enclose\nLess Than",
    help: "https://mlochbaum.github.io/BQN/help/enclose_lessthan.html" },
  { glyph: ">", key: null, type: TokenType.FUNCTION,
    hint: "Merge\nGreater Than",
    help: "https://mlochbaum.github.io/BQN/help/merge_greaterthan.html" },
  { glyph: "≥", key: ">", type: TokenType.FUNCTION,
    hint: "Greater Than or Equal to",
    help: "https://mlochbaum.github.io/BQN/help/greaterthanorequalto.html" },
  { glyph: "=", key: null, type: TokenType.FUNCTION,
    hint: "Rank\nEquals",
    help: "https://mlochbaum.github.io/BQN/help/rank_equals.html" },
  { glyph: "≠", key: "/", type: TokenType.FUNCTION,
    hint: "Length\nNot Equals",
    help: "https://mlochbaum.github.io/BQN/help/length_notequals.html" },
  { glyph: "≡", key: "m", type: TokenType.FUNCTION,
    hint: "Depth\nMatch",
    help: "https://mlochbaum.github.io/BQN/help/depth_match.html" },
  { glyph: "≢", key: "M", type: TokenType.FUNCTION,
    hint: "Shape\nNot Match",
    help: "https://mlochbaum.github.io/BQN/help/shape_notmatch.html" },
  { glyph: "⊣", key: "{", type: TokenType.FUNCTION,
    hint: "Identity\nLeft",
    help: "https://mlochbaum.github.io/BQN/help/identity_left.html" },
  { glyph: "⊢", key: "}", type: TokenType.FUNCTION,
    hint: "Identity\nRight",
    help: "https://mlochbaum.github.io/BQN/help/identity_right.html" },
  { glyph: "⥊", key: "z", type: TokenType.FUNCTION,
    hint: "Deshape\nReshape",
    help: "https://mlochbaum.github.io/BQN/help/deshape_reshape.html" },
  { glyph: "∾", key: ",", type: TokenType.FUNCTION,
    hint: "Join\nJoin to",
    help: "https://mlochbaum.github.io/BQN/help/join_jointo.html" },
  { glyph: "≍", key: ".", type: TokenType.FUNCTION,
    hint: "Solo\nCouple",
    help: "https://mlochbaum.github.io/BQN/help/solo_couple.html" },
  { glyph: "⋈", key: "Z", type: TokenType.FUNCTION,
    hint: "Enlist\nPair",
    help: "https://mlochbaum.github.io/BQN/help/enlist_pair.html" },
  { glyph: "↑", key: "r", type: TokenType.FUNCTION,
    hint: "Prefixes\nTake",
    help: "https://mlochbaum.github.io/BQN/help/prefixes_take.html" },
  { glyph: "↓", key: "c", type: TokenType.FUNCTION,
    hint: "Suffixes\nDrop",
    help: "https://mlochbaum.github.io/BQN/help/suffixes_drop.html" },
  { glyph: "↕", key: "d", type: TokenType.FUNCTION,
    hint: "Range\nWindows",
    help: "https://mlochbaum.github.io/BQN/help/range_windows.html" },
  { glyph: "«", key: "H", type: TokenType.FUNCTION,
    hint: "Shift Before",
    help: "https://mlochbaum.github.io/BQN/help/shiftbefore.html" },
  { glyph: "»", key: "L", type: TokenType.FUNCTION,
    hint: "Shift After",
    help: "https://mlochbaum.github.io/BQN/help/shiftafter.html" },
  { glyph: "⌽", key: "q", type: TokenType.FUNCTION,
    hint: "Reverse\nRotate",
    help: "https://mlochbaum.github.io/BQN/help/reverse_rotate.html" },
  { glyph: "⍉", key: "a", type: TokenType.FUNCTION,
    hint: "Transpose\nReorder axes",
    help: "https://mlochbaum.github.io/BQN/help/transpose_reorderaxes.html" },
  { glyph: "/", key: null, type: TokenType.FUNCTION,
    hint: "Indices\nReplicate",
    help: "https://mlochbaum.github.io/BQN/help/indices_replicate.html" },
  { glyph: "⍋", key: "T", type: TokenType.FUNCTION,
    hint: "Grade Up\nBins Up",
    help: "https://mlochbaum.github.io/BQN/help/gradeup_binsup.html" },
  { glyph: "⍒", key: "V", type: TokenType.FUNCTION,
    hint: "Grade Down\nBins Down",
    help: "https://mlochbaum.github.io/BQN/help/gradedown_binsdown.html" },
  { glyph: "⊏", key: "i", type: TokenType.FUNCTION,
    hint: "First Cell\nSelect",
    help: "https://mlochbaum.github.io/BQN/help/firstcell_select.html" },
  { glyph: "⊑", key: "I", type: TokenType.FUNCTION,
    hint: "First\nPick",
    help: "https://mlochbaum.github.io/BQN/help/first_pick.html" },
  { glyph: "⊐", key: "o", type: TokenType.FUNCTION,
    hint: "Classify\nIndex of",
    help: "https://mlochbaum.github.io/BQN/help/classify_indexof.html" },
  { glyph: "⊒", key: "O", type: TokenType.FUNCTION,
    hint: "Occurrence Count\nProgressive Index of",
    help: "https://mlochbaum.github.io/BQN/help/occurrencecount_progressiveindexof.html" },
  { glyph: "∊", key: "e", type: TokenType.FUNCTION,
    hint: "Mark First\nMember of",
    help: "https://mlochbaum.github.io/BQN/help/markfirst_memberof.html" },
  { glyph: "⍷", key: "E", type: TokenType.FUNCTION,
    hint: "Deduplicate\nFind",
    help: "https://mlochbaum.github.io/BQN/help/deduplicate_find.html" },
  { glyph: "⊔", key: "u", type: TokenType.FUNCTION,
    hint: "Group Indices\nGroup",
    help: "https://mlochbaum.github.io/BQN/help/groupindices_group.html" },
  { glyph: "!", key: null, type: TokenType.FUNCTION,
    hint: "Assert\nAssert with message",
    help: "https://mlochbaum.github.io/BQN/help/assert_assertwithmessage.html" },
  { glyph: "˙", key: "\"", type: TokenType.MODIFIER,
    hint: "Constant",
    help: "https://mlochbaum.github.io/BQN/help/constant.html" },
  { glyph: "˜", key: "`", type: TokenType.MODIFIER,
    hint: "Self/Swap",
    help: "https://mlochbaum.github.io/BQN/help/self_swap.html" },
  { glyph: "∘", key: "j", type: TokenType.MODIFIER_2,
    hint: "Atop",
    help: "https://mlochbaum.github.io/BQN/help/atop.html" },
  { glyph: "○", key: "k", type: TokenType.MODIFIER_2,
    hint: "Over",
    help: "https://mlochbaum.github.io/BQN/help/over.html" },
  { glyph: "⊸", key: "h", type: TokenType.MODIFIER_2,
    hint: "Before/Bind",
    help: "https://mlochbaum.github.io/BQN/help/before_bind.html" },
  { glyph: "⟜", key: "l", type: TokenType.MODIFIER_2,
    hint: "After/Bind",
    help: "https://mlochbaum.github.io/BQN/help/after_bind.html" },
  { glyph: "⌾", key: "K", type: TokenType.MODIFIER_2,
    hint: "Under",
    help: "https://mlochbaum.github.io/BQN/help/under.html" },
  { glyph: "⊘", key: "%", type: TokenType.MODIFIER_2,
    hint: "Valences",
    help: "https://mlochbaum.github.io/BQN/help/valences.html" },
  { glyph: "◶", key: "$", type: TokenType.MODIFIER_2,
    hint: "Choose",
    help: "https://mlochbaum.github.io/BQN/help/choose.html" },
  { glyph: "⎊", key: "^", type: TokenType.MODIFIER_2,
    hint: "Catch",
    help: "https://mlochbaum.github.io/BQN/help/catch.html" },
  { glyph: "⎉", key: "!", type: TokenType.MODIFIER_2,
    hint: "Rank",
    help: "https://mlochbaum.github.io/BQN/help/rank.html" },
  { glyph: "˘", key: "1", type: TokenType.MODIFIER,
    hint: "Cells",
    help: "https://mlochbaum.github.io/BQN/help/cells.html" },
  { glyph: "⚇", key: "@", type: TokenType.MODIFIER_2,
    hint: "Depth",
    help: "https://mlochbaum.github.io/BQN/help/depth.html" },
  { glyph: "¨", key: "2", type: TokenType.MODIFIER,
    hint: "Each",
    help: "https://mlochbaum.github.io/BQN/help/each.html" },
  { glyph: "⌜", key: "4", type: TokenType.MODIFIER,
    hint: "Table",
    help: "https://mlochbaum.github.io/BQN/help/table.html" },
  { glyph: "⍟", key: "#", type: TokenType.MODIFIER_2,
    hint: "Repeat",
    help: "https://mlochbaum.github.io/BQN/help/repeat.html" },
  { glyph: "⁼", key: "3", type: TokenType.MODIFIER,
    hint: "Undo",
    help: "https://mlochbaum.github.io/BQN/help/undo.html" },
  { glyph: "´", key: "5", type: TokenType.MODIFIER,
    hint: "Fold",
    help: "https://mlochbaum.github.io/BQN/help/fold.html" },
  { glyph: "˝", key: "6", type: TokenType.MODIFIER,
    hint: "Insert",
    help: "https://mlochbaum.github.io/BQN/help/insert.html" },
  { glyph: "`", key: null, type: TokenType.MODIFIER,
    hint: "Scan",
    help: "https://mlochbaum.github.io/BQN/help/scan.html" },
  { glyph: "←", key: "[", type: TokenType.GETS,
    hint: "Define",
    help: "https://mlochbaum.github.io/BQN/help/define.html" },
  { glyph: "⇐", key: "?", type: TokenType.GETS,
    hint: "Export",
    help: "https://mlochbaum.github.io/BQN/help/export.html" },
  { glyph: "↩", key: "'", type: TokenType.GETS,
    hint: "Change",
    help: "https://mlochbaum.github.io/BQN/help/change.html" },
  { glyph: "⋄", key: ";", type: TokenType.SEPARATOR,
    hint: "Separator",
    help: "https://mlochbaum.github.io/BQN/help/separator.html" },
  { glyph: ",", key: null, type: TokenType.SEPARATOR,
    hint: "Separator",
    help: "https://mlochbaum.github.io/BQN/help/separator.html" },
  { glyph: ".", key: null, type: TokenType.VALUE,
    hint: "Namespace field",
    help: "https://mlochbaum.github.io/BQN/help/namespacefield.html" },
  { glyph: "(", key: null, type: TokenType.PAREN, paren: ")",
    hint: "Begin expression",
    help: "https://mlochbaum.github.io/BQN/help/beginexpression.html" },
  { glyph: ")", key: null, type: TokenType.PAREN,
    hint: "End expression",
    help: "https://mlochbaum.github.io/BQN/help/endexpression.html" },
  { glyph: "{", key: null, type: TokenType.BRACE, paren: "}",
    hint: "Begin block",
    help: "https://mlochbaum.github.io/BQN/help/beginblock.html" },
  { glyph: "}", key: null, type: TokenType.BRACE,
    hint: "End block",
    help: "https://mlochbaum.github.io/BQN/help/endblock.html" },
  { glyph: ";", key: null, type: TokenType.HEAD,
    hint: "Next body",
    help: "https://mlochbaum.github.io/BQN/help/nextbody.html" },
  { glyph: ":", key: null, type: TokenType.HEAD,
    hint: "Header",
    help: "https://mlochbaum.github.io/BQN/help/header.html" },
  { glyph: "?", key: null, type: TokenType.HEAD,
    hint: "Predicate",
    help: "https://mlochbaum.github.io/BQN/help/predicate.html" },
  { glyph: "⟨", key: "(", type: TokenType.BRACKET, paren: "⟩",
    hint: "Begin list",
    help: "https://mlochbaum.github.io/BQN/help/beginlist.html" },
  { glyph: "⟩", key: ")", type: TokenType.BRACKET,
    hint: "End list",
    help: "https://mlochbaum.github.io/BQN/help/endlist.html" },
  { glyph: "[", key: null, type: TokenType.BRACKET, paren: "]",
    hint: "Begin array",
    help: "https://mlochbaum.github.io/BQN/help/beginarray.html" },
  { glyph: "]", key: null, type: TokenType.BRACKET,
    hint: "End array",
    help: "https://mlochbaum.github.io/BQN/help/endarray.html" },
  { glyph: "‿", key: " ", type: TokenType.LIGATURE,
    hint: "Strand",
    help: "https://mlochbaum.github.io/BQN/help/strand.html" },
  { glyph: "·", key: ":", type: TokenType.NOTHING,
    hint: "Nothing",
    help: "https://mlochbaum.github.io/BQN/help/nothing.html" },
  { glyph: "•", key: "0", type: TokenType.VALUE,
    hint: "System",
    help: "https://mlochbaum.github.io/BQN/help/system.html" },
  { glyph: "𝕨", key: "w", type: TokenType.VALUE,
    hint: "Left argument",
    help: "https://mlochbaum.github.io/BQN/help/leftargument.html" },
  { glyph: "𝕎", key: "W", type: TokenType.FUNCTION,
    hint: "Left argument (as function)",
    help: "https://mlochbaum.github.io/BQN/help/leftargument.html" },
  { glyph: "𝕩", key: "x", type: TokenType.VALUE,
    hint: "Right argument",
    help: "https://mlochbaum.github.io/BQN/help/rightargument.html" },
  { glyph: "𝕏", key: "X", type: TokenType.FUNCTION,
    hint: "Right argument (as function)",
    help: "https://mlochbaum.github.io/BQN/help/rightargument.html" },
  { glyph: "𝕗", key: "f", type: TokenType.VALUE,
    hint: "Modifier left operand (as subject)",
    help: "https://mlochbaum.github.io/BQN/help/modifierleftoperand.html" },
  { glyph: "𝔽", key: "F", type: TokenType.FUNCTION,
    hint: "Modifier left operand",
    help: "https://mlochbaum.github.io/BQN/help/modifierleftoperand.html" },
  { glyph: "𝕘", key: "g", type: TokenType.VALUE,
    hint: "2-modifier right operand (as subject)",
    help: "https://mlochbaum.github.io/BQN/help/2-modifierrightoperand.html", },
  { glyph: "𝔾", key: "G", type: TokenType.FUNCTION,
    hint: "2-modifier right operand",
    help: "https://mlochbaum.github.io/BQN/help/2-modifierrightoperand.html", },
  { glyph: "𝕤", key: "s", type: TokenType.VALUE,
    hint: "Current function (as subject)",
    help: "https://mlochbaum.github.io/BQN/help/currentfunction.html" },
  { glyph: "𝕊", key: "S", type: TokenType.FUNCTION,
    hint: "Current function",
    help: "https://mlochbaum.github.io/BQN/help/currentfunction.html" },
  { glyph: "𝕣", key: "R", type: TokenType.MODIFIER,
    hint: "Current modifier",
    help: "https://mlochbaum.github.io/BQN/help/currentmodifier.html" },
  { glyph: "¯", key: "9", type: TokenType.NOTHING,
    hint: "Minus",
    help: "https://mlochbaum.github.io/BQN/help/minus.html" },
  { glyph: "π", key: "p", type: TokenType.NOTHING,
    hint: "Pi",
    help: "https://mlochbaum.github.io/BQN/help/pi.html" },
  { glyph: "∞", key: "8", type: TokenType.NOTHING,
    hint: "Infinity",
    help: "https://mlochbaum.github.io/BQN/help/infinity.html" },
  { glyph: "@", key: null, type: TokenType.STRING,
    hint: "Null character",
    help: "https://mlochbaum.github.io/BQN/help/nullcharacter.html" },
  { glyph: "'", key: null, type: TokenType.STRING, paren: "'",
    hint: "Character",
    help: "https://mlochbaum.github.io/BQN/help/character.html" },
  { glyph: "\"", key: null, type: TokenType.STRING, paren: "\"",
    hint: "String",
    help: "https://mlochbaum.github.io/BQN/help/string.html" },
  { glyph: "#", key: null, type: TokenType.COMMENT,
    hint: "Comment",
    help: "https://mlochbaum.github.io/BQN/help/comment.html" },
  { glyph: "⍎", key: "&", type: TokenType.UNUSED },
  { glyph: "⍕", key: "*", type: TokenType.UNUSED },
  { glyph: "→", key: "]", type: TokenType.UNUSED },
  { glyph: "↙", key: "Q", type: TokenType.UNUSED },
  { glyph: "⍳", key: "P", type: TokenType.UNUSED },
  { glyph: "↖", key: "A", type: TokenType.UNUSED },
] as Glyph[];
