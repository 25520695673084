// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@font-face {
  font-family: BQN386;
  src: url(static/BQN386.ttf);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #root {
  height: 100%;
}

:root {
  background: var(--background);
  color: var(--text-color);
  font-family: BQN386, Consolas, monospace;
  font-size: 20px;
  line-height: 1.4;
}

a {
  color: var(--link-color);
  text-decoration: none;
}

.syn-head { color: var(--syn-head); }
.syn-value { color: var(--syn-value); }
.syn-function { color: var(--syn-function); }
.syn-modifier { color: var(--syn-modifier); }
.syn-modifier2 { color: var(--syn-modifier2); }
.syn-gets { color: var(--syn-gets); }
.syn-paren { color: var(--syn-paren); }
.syn-ligature { color: var(--syn-ligature); }
.syn-separator { color: var(--syn-separator); }
.syn-bracket { color: var(--syn-bracket); }
.syn-brace { color: var(--syn-brace); }
.syn-nothing { color: var(--syn-nothing); }
.syn-number { color: var(--syn-number); }
.syn-comment { color: var(--syn-comment); }
.syn-string { color: var(--syn-string); }
.error { color: var(--error); }
`, "",{"version":3,"sources":["webpack://./src/globals.css"],"names":[],"mappings":";AACA;EACE,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,6BAA6B;EAC7B,wBAAwB;EACxB,wCAAwC;EACxC,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,wBAAwB;EACxB,qBAAqB;AACvB;;AAEA,YAAY,sBAAsB,EAAE;AACpC,aAAa,uBAAuB,EAAE;AACtC,gBAAgB,0BAA0B,EAAE;AAC5C,gBAAgB,0BAA0B,EAAE;AAC5C,iBAAiB,2BAA2B,EAAE;AAC9C,YAAY,sBAAsB,EAAE;AACpC,aAAa,uBAAuB,EAAE;AACtC,gBAAgB,0BAA0B,EAAE;AAC5C,iBAAiB,2BAA2B,EAAE;AAC9C,eAAe,yBAAyB,EAAE;AAC1C,aAAa,uBAAuB,EAAE;AACtC,eAAe,yBAAyB,EAAE;AAC1C,cAAc,wBAAwB,EAAE;AACxC,eAAe,yBAAyB,EAAE;AAC1C,cAAc,wBAAwB,EAAE;AACxC,SAAS,mBAAmB,EAAE","sourcesContent":["\n@font-face {\n  font-family: BQN386;\n  src: url(static/BQN386.ttf);\n}\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\nhtml, body, #root {\n  height: 100%;\n}\n\n:root {\n  background: var(--background);\n  color: var(--text-color);\n  font-family: BQN386, Consolas, monospace;\n  font-size: 20px;\n  line-height: 1.4;\n}\n\na {\n  color: var(--link-color);\n  text-decoration: none;\n}\n\n.syn-head { color: var(--syn-head); }\n.syn-value { color: var(--syn-value); }\n.syn-function { color: var(--syn-function); }\n.syn-modifier { color: var(--syn-modifier); }\n.syn-modifier2 { color: var(--syn-modifier2); }\n.syn-gets { color: var(--syn-gets); }\n.syn-paren { color: var(--syn-paren); }\n.syn-ligature { color: var(--syn-ligature); }\n.syn-separator { color: var(--syn-separator); }\n.syn-bracket { color: var(--syn-bracket); }\n.syn-brace { color: var(--syn-brace); }\n.syn-nothing { color: var(--syn-nothing); }\n.syn-number { color: var(--syn-number); }\n.syn-comment { color: var(--syn-comment); }\n.syn-string { color: var(--syn-string); }\n.error { color: var(--error); }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
